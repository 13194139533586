<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">客户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">客户列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">客户详情</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业交易量统计详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>合计</span>
          </div>
        </div>
        <el-descriptions class="margin-top" style="margin-top:20px" :column="3">
          <el-descriptions-item label="企业名称">{{ detailInfo.name||'--'}}</el-descriptions-item>
          <el-descriptions-item label="企业统一信用编码">{{detailInfo.idCardNumber}}</el-descriptions-item>

          <el-descriptions-item label="累计发薪额">{{detailInfo.tatolMoney}}</el-descriptions-item>
          <el-descriptions-item label="当月发薪额">{{detailInfo.monthMoney}}</el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>明细</span>
          </div>
        </div>
        <el-table :data="tableData" style="margin:20px;">
          <el-table-column prop="date" label="年月" align="center"></el-table-column>
          <el-table-column prop="totalMoney" label="累计发薪额/元" align="center"></el-table-column>
        </el-table>
      </el-card>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detailInfo: {
        name: '银企直联',
        idCardNumber: 'x135468794556',
        tatolMoney: '200000',
        monthMoney: '10000',
      },
      code: '',
      tableData: [{

        date: '2021-10',
        totalMoney: '20000'
      }, {

        date: '2021-09',
        totalMoney: '20001'
      }, {

        date: '2021-08',
        totalMoney: '200000'
      }, {

        date: '2021-07',
        totalMoney: '2000000'
      }]
    }
  },
  created () {
    this.code = this.$route.query.code;
    console.log(this.detailInfo)
    // this.logoData()
  },
  methods: {
    logoData () {
      // const params = {
      //   code: this.code
      // };
      // customerInfo(params).then((res) => {
      //   this.detailInfo = res;
      //   console.log(this.detailInfo)

      // });
    }
  },
}
</script>